import { favoritesClient } from '@y2/api-clients/favorites';
import { FavoriteItem } from '@y2/api-clients/favorites.types';
import {
  createFeedClient,
  LikedItemData,
  AxiosResponse,
} from '@y2/api-clients';

export type { FavoriteItem, LikedItemData, AxiosResponse };

export const favoriteClient = favoritesClient();
export const legacyFeedClient = createFeedClient();
