import { EventData, PageEventData } from '../types';
import { defaultEventName, defaultPageEventName } from '../const';
import {
  isServerSide,
  getEnvironmentEventData,
  getReactPageIndicator,
} from '../helpers';
import { getRversionEventData, getIsBotIndication } from '../../helpers';
import type { DataLayerEvent } from './types';
import { triggerPostPageEvent } from '../../triggers';
import { addBrazeCampaignIndication } from '../../campaigns/braze';

const pushDataLayer = (event: DataLayerEvent) => {
  window.dataLayer = window.dataLayer || [];
  window.dataLayer.push(event);
};

const emptyQueue = () => {
  const eventsData = Array.isArray(window.prePageEventQueue)
    ? window.prePageEventQueue
    : [];

  eventsData.forEach((event) => pushDataLayer(event));
  window.prePageEventQueue = 'submitted';
};

const pushToQueue = (event: DataLayerEvent) => {
  if (window.prePageEventQueue !== 'submitted') {
    window.prePageEventQueue = window.prePageEventQueue || [];
    window.prePageEventQueue.push(event);
  }
};

const createEventPusher = (eventData: EventData) => {
  if (isServerSide()) {
    return;
  }

  const eventToPush = {
    event: defaultEventName,
    ...getIsBotIndication(),
    ...addBrazeCampaignIndication(),
    ...eventData,
  };

  if (window.prePageEventQueue !== 'submitted') {
    pushToQueue(eventToPush);
  } else {
    pushDataLayer(eventToPush);
  }
};

const createPageEventPusher = (eventData: PageEventData) => {
  if (isServerSide()) {
    return;
  }

  pushDataLayer({
    event: defaultPageEventName,
    ...eventData,
    ...getEnvironmentEventData(),
    ...getRversionEventData(),
    ...getReactPageIndicator(),
    ...getIsBotIndication(),
    ...addBrazeCampaignIndication(),
  });
  emptyQueue();
  triggerPostPageEvent();
};

export const analytics = {
  pushEvent: createEventPusher,
  pushPageEvent: createPageEventPusher,
};
