import { useEffect, useRef, DependencyList, EffectCallback } from 'react';

export const useEffectWithoutFirstRender = (
  effect: EffectCallback,
  dependencies: DependencyList,
): void => {
  const hasMounted = useRef(false);

  useEffect(() => {
    if (hasMounted.current) {
      const cleanup = effect();
      return cleanup;
    } else {
      hasMounted.current = true;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependencies);
};

export default useEffectWithoutFirstRender;
