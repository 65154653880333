import { useEffect, useState } from 'react';
import { log } from '@y2/log';
import { chatClient } from '@y2/api-clients/chat';
import { useChatUpdates } from '@y2/socket-clients/sendbird-updates';
import { ChatMessageNotification } from '@y2/socket-clients/sendbird-updates/types';
import type { AxiosError } from '@y2/api-clients';
import { STATUS } from '@y2/error-handlers';

const client = chatClient();
const handleUnreadChatMessagesCountErrors = (err: AxiosError) => {
  const isServerError = err.response?.status === STATUS.internalError;
  isServerError
    ? log.error(err, 'updateUnreadMessageCount failed')
    : log.info(err.message);
};
export const useUnreadChatMessagesCount = (
  isUserLoggedIn: boolean,
  onNewMessageCallBack?: (message: ChatMessageNotification) => void,
) => {
  const [unreadChatMessagesCount, setUnreadChatMessagesCount] = useState(0);

  const updateUnreadMessageCount = async () => {
    if (!isUserLoggedIn) return;
    try {
      const {
        data: {
          data: { unreadCount },
        },
      } = await client.getUnreadMessageCount();
      setUnreadChatMessagesCount(unreadCount ?? 0);
      // eslint-disable-next-line custom-rules/enforce-catch-err-handling
    } catch (err: unknown) {
      handleUnreadChatMessagesCountErrors(err as AxiosError);
    }
  };

  const useChatUpdatesOptions = {
    isUserLoggedIn,
    onBfCache: updateUnreadMessageCount,
    onMessage: (msgRes: { message: ChatMessageNotification }) => {
      const { isBlockingSender } = msgRes.message || {};

      if (isBlockingSender) return;

      setUnreadChatMessagesCount(msgRes.message.totalUnreadMessageCount);

      onNewMessageCallBack?.(msgRes.message);
    },
  };

  useChatUpdates(useChatUpdatesOptions);

  useEffect(() => {
    if (!isUserLoggedIn) {
      setUnreadChatMessagesCount(0);
    }
  }, [isUserLoggedIn]);

  return unreadChatMessagesCount;
};
