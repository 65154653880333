import styles from './close-button.module.scss';
import React from 'react';
import noop from 'lodash/noop';
import { CloseIcon } from '../../icons';
import text from './close-button.text.json';

type Props = {
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
};

export const CloseButton = ({ onCloseClick = noop }: Props) => (
  <button
    className={styles.close}
    onClick={onCloseClick}
    data-testid="tooltip-close-button"
    aria-label={text.altButton}
  >
    <CloseIcon className={styles.icon} />
  </button>
);
