import React from 'react';
import env from '@y2/environments';
import { SubCategoryItem } from '../../../sub-category-item/sub-category-item';
import { CategoryItem } from '../../../category-item/category-item';
import texts from './popular-rent-pages.texts.json';

const YAD_ONE_URL = `${env.routes.mainSite}/yad1`;
const YAD_ONE_NEW_PROJECTS = `${YAD_ONE_URL}/newprojects`;

export const PopularRentPagesCategory = () => {
  return (
    <CategoryItem title={texts.title}>
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?category=1`}
        title={texts.newApartmentsForSale}
      />
      <SubCategoryItem
        link={YAD_ONE_NEW_PROJECTS}
        title={texts.contractorApartments}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?category=2`}
        title={texts.newApartmentsForRent}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?category=4`}
        title={texts.newCommercialRealEstate}
      />
      <SubCategoryItem
        link={YAD_ONE_NEW_PROJECTS}
        title={texts.realEstateCompanies}
      />
      <SubCategoryItem link={YAD_ONE_NEW_PROJECTS} title={texts.newHouses} />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?propertyType=25`}
        title={texts.duplexes}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?propertyType=23`}
        title={texts.housesNearTheSea}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?propertyType=36`}
        title={texts.investmentLand}
      />
      <SubCategoryItem
        link={`${YAD_ONE_URL}/projectsale`}
        title={texts.promotions}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=100&area=7&city=3000`}
        title={texts.newProjectsInJerusalem}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=2&area=4&city=7900`}
        title={texts.newProjectsInPetahTikva}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=2&area=1&city=5000`}
        title={texts.newProjectsInTelAviv}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=2&area=9&city=8300`}
        title={texts.newProjectsInRishonLezion}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=41&area=21&city=0070`}
        title={texts.newProjectsInAshdod}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=41&area=21&city=7100`}
        title={texts.newProjectsInAshkelon}
      />
      <SubCategoryItem
        link={`${YAD_ONE_NEW_PROJECTS}?topArea=25&area=5&city=4000`}
        title={texts.newProjectsInHaifa}
      />
    </CategoryItem>
  );
};
