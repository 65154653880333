import cn from 'classnames';
import styles from './info-column.module.scss';

type Props = {
  title: string;
  subTitle?: string;
  comment?: string;
};

export const InfoColumn = ({ title, subTitle, comment }: Props) => (
  <span className={cn(styles.col, styles.infoCol)} data-testid="info-column">
    <span className={styles.row}>{title}</span>
    <span className={cn(styles.row, styles.description)}>
      {subTitle || comment}
    </span>
  </span>
);
