import * as localFavorites from './local-storage';
import type { FeedClient } from '@y2/api-clients';
import { recommerceFeedClient } from '@y2/api-clients/recommerce-feed';

const recommerceClient = recommerceFeedClient();
export async function getAll(feedClient: FeedClient) {
  const likedItems = localFavorites.getAll();
  const mappedLikedItems: Record<string, localFavorites.LikedItemStorage> = {};
  const resAll = await Promise.all(
    likedItems.map(async (item) => {
      mappedLikedItems[item.adId] = item;

      const { data } = await feedClient.get(item.adId);
      return data;
    }),
  );
  return { resAll, mappedLikedItems };
}

export async function getAllRecommerce() {
  const likedItems = localFavorites.getAllRecommerce();
  const mappedLikedItems: Record<string, localFavorites.LikedItemStorage> = {};

  const resAll = await Promise.all(
    likedItems.map(async (item) => {
      mappedLikedItems[item.adId] = item;
      const data = await recommerceClient.getProductsByShopifyIds([
        Number(item.adId),
      ]);
      return data[0];
    }),
  );
  return { resAll, mappedLikedItems };
}

export function add(id: string) {
  const list = localFavorites.getAll();
  const updatedList: localFavorites.LikedItemStorage[] = [
    ...list,
    { adId: id, comment: '', updatedAt: new Date().toISOString() },
  ];
  localFavorites.setAll(updatedList);
}

export function addRecommerce(id: string) {
  const list = localFavorites.getAllRecommerce();
  const updatedList: localFavorites.LikedItemStorage[] = [
    ...list,
    { adId: id, comment: '', updatedAt: new Date().toISOString() },
  ];
  localFavorites.setAllRecommerce(updatedList);
}

export function remove(id: string) {
  const list = localFavorites.getAll();
  const updatedList = list.filter((item) => item.adId !== id);
  localFavorites.setAll(updatedList);
}

export function removeRecommerce(id: string) {
  const list = localFavorites.getAllRecommerce();
  const updatedList = list.filter((item) => item.adId !== id);
  localFavorites.setAllRecommerce(updatedList);
}
