import { UseQueryOptions } from '@tanstack/react-query';
import { latestSearches } from './clients';

const getQueryKey = () => ['latest-searches', 'count'];

export const getLatestSearchesCount = async (): Promise<number> => {
  const {
    data: { data },
  } = await latestSearches.count();
  return data;
};

export const getLatestSearchesCountOptions = (): UseQueryOptions<number> => {
  return {
    queryKey: getQueryKey(),
    queryFn: () => getLatestSearchesCount(),
  };
};
