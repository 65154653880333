import { MenuListNavbar, MenuItem, MenuCategory } from '@y2/header';
import { pushHeaderUserItemEvent } from '../../../features/analytics/feed-events';
import texts from './header-main-menu.texts.json';
import styles from './header-main-menu.module.scss';
import Link from 'next/link';
import {
  ParasolIcon,
  HomeIcon,
  BusinessReIcon,
  AnnouncementIcon,
} from '@y2/mango/components/icons';
import cn from 'classnames';
import { useNativeApp } from '@y2/hooks/use-native-app';

const isNextLink = (url: string) => new RegExp(`^/yad1/.*$`).test(url);
import env from '@y2/environments';

export const menuData: MenuCategory[] = [
  {
    categoryText: texts.nadlanCompanies,
    url: `${env.routes.mainSite}/yad1/developers`,
    rowsInDesktop: 0,
    newTab: false,
    subCategories: [],
    icon: BusinessReIcon,
  },
  {
    categoryText: texts.specialDeals,
    url: `${env.routes.mainSite}/yad1/projectsale`,
    rowsInDesktop: 0,
    newTab: false,
    subCategories: [],
    icon: AnnouncementIcon,
  },
];

export const HeaderMainMenu = () => {
  const isNativeApp = useNativeApp();

  if (isNativeApp) return null;

  return (
    <MenuListNavbar>
      <li className={cn(styles.headerLink, 'mobile-only')}>
        <HomeIcon className={styles.icon} />
        <Link href="/" onClick={() => pushHeaderUserItemEvent(texts.homePage)}>
          {texts.homePage}
        </Link>
      </li>
      {menuData.map((item, idx) => (
        <MenuItem
          key={`${item.categoryText}_${idx}`}
          item={item}
          isNextLink={isNextLink}
          onClick={(text) => {
            pushHeaderUserItemEvent(text);
          }}
          disableAnalyticsEvents
        />
      ))}
      {/* <li className={styles.headerLink}>
            <ParasolIcon className={styles.icon} />
            <Link
              href="/abroad"
              onClick={() => pushHeaderUserItemEvent(texts.abroadRealestate)}
            >
              {texts.abroadRealestate}
            </Link>
            <span className={styles.badge}>{texts.new}</span>
          </li> */}
    </MenuListNavbar>
  );
};
