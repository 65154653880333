import cn from 'classnames';
import styles from './profile-text.module.scss';
import text from '../user-drop-container.text.json';

export const ProfileText = ({
  isNotLogged,
  fullName,
}: {
  isNotLogged: boolean;
  fullName: string;
}) => {
  const [nameFirstPart] = fullName.split(/\s+/);

  return isNotLogged ? (
    <span className={styles.profileBoxText}>{text.GO_TO_LOGIN_PAGE}</span>
  ) : (
    <span className={cn(styles.profileBoxText, styles.wideDesktop)}>
      {nameFirstPart}
    </span>
  );
};
