export const PLACEMENTS = [
  'left',
  'right',
  'top',
  'top-left',
  'top-right',
  'bottom',
  'bottom-left',
  'bottom-right',
] as const;

export type Placement = (typeof PLACEMENTS)[number];

export const getPointerDirection = (placement: Placement) => {
  switch (placement) {
    case 'bottom':
    case 'bottom-left':
    case 'bottom-right':
      return 'pointTop';
    case 'left':
      return 'pointRight';
    case 'right':
      return 'pointLeft';
    default:
      return 'pointBottom';
  }
};
