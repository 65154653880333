import React from 'react';
import cn from 'classnames';
import { sizes, variants, types } from './consts';
import style from './badge.module.scss';

type NativeSpanProps = React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLSpanElement>,
  HTMLSpanElement
>;

export type BadgeProps = NativeSpanProps & {
  ariaLabel: string;
  variant?: (typeof variants)[number];
  type?: (typeof types)[number];
  size?: (typeof sizes)[number];
  label?: string;
  style?: object;
  className?: string;
};

export const Badge = ({
  ariaLabel,
  type = 'badge',
  variant = 'default',
  size = 'small',
  label = '',
  className,
  ...htmlAttributes
}: BadgeProps) => (
  <span
    data-testid="badge"
    aria-label={ariaLabel}
    className={cn(
      style.badge,
      style[type],
      style[variant],
      style[size],
      className,
    )}
    {...htmlAttributes}
  >
    {size !== 'dot' ? label : ''}
  </span>
);
