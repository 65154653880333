import axios, { ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import {
  FavoriteResponse,
  FavoritesWithShopifyResponse,
  RemoveFavoriteItemResponse,
} from './favorites.types';

const serviceName = 'favorites';

/**
 * client for `favorites` service
 * @see https://gw.yad2.io/favorites/api-docs/#
 */
export const favoritesClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    getAll: () => client.get<FavoriteResponse>('/ads/v3'),
    add: (token: string) =>
      client.post('/ads', {
        action: 'like',
        orderId: token,
      }),
    addMulti: (tokens: string[]) =>
      client.post('/ads', {
        action: 'like',
        ordersIds: tokens,
      }),
    remove: (token: string) => client.delete(`/ads/${token}`),
    getWithShopify: () => client.get<FavoritesWithShopifyResponse>('/ads/v2'),
    removeWithShopify: (token: string) =>
      client.delete<ResponseBody<RemoveFavoriteItemResponse>>(
        `/ads/v2/${token}`,
      ),
    addComment: (token: string, comment: string) =>
      client.post('/comments', {
        orderId: token,
        comment,
      }),
    removeComment: (token: string) => client.delete(`/comments/${token}`),
  };
};

export type FavoritesClient = ReturnType<typeof favoritesClient>;
