/* istanbul ignore file */
import React, { useRef, useState } from 'react';
import cn from 'classnames';
import styles from './user-drop-container.module.scss';
import text from './user-drop-container.text.json';
import { ProfileText } from './profile-text/profile-text';
import UserDropList from '../list/user-drop-list';
import { usePopupViewportPosition } from '@y2/hooks/use-popup-viewport-position';
import { ProfileCircle } from './profile-circle/profile-circle';

type Props = {
  onClick: () => void;
  userInitials?: string;
  userProfImg?: string;
  hasHoverEffects?: boolean;
  className?: string;
  theme?: 'yad1' | 'primary';
} & React.ComponentProps<typeof UserDropList>;

const UserDropContainer: React.VFC<Props> = ({
  onClick,
  userProfImg = '',
  userInitials = '',
  className = '',
  hasHoverEffects = true,
  theme = 'primary',
  ...userDropListProps
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const dropSectionRef = useRef<HTMLDivElement>(null);
  usePopupViewportPosition(dropSectionRef, isOpen);
  const isNotLogged = !userDropListProps.userFullName;

  return (
    <div
      className={cn(
        styles.headerUserDrop,
        isOpen && styles['is-open'],
        className,
      )}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
    >
      <div
        className={cn(
          styles.userButtonWrapper,
          userDropListProps.userFullName &&
            styles['is-inline-for-user-content'],
        )}
      >
        <button
          className={cn(
            styles.userProfileButton,
            hasHoverEffects && styles.hoverEffects,
          )}
          onClick={onClick}
          aria-label={text.PROFILE_MENU}
          data-nagish="header-user-profile-button"
        >
          <ProfileCircle
            isConnected={!!userInitials}
            theme={theme}
            userProfileImg={userProfImg}
            userInitials={userInitials}
          />
          <ProfileText
            isNotLogged={isNotLogged}
            fullName={userDropListProps.userFullName || ''}
          />
        </button>
      </div>

      <section className={styles.userOptionsContainer} ref={dropSectionRef}>
        <UserDropList {...userDropListProps} />
      </section>
    </div>
  );
};

export default UserDropContainer;
