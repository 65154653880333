import { useState, ComponentProps } from 'react';
import {
  HeartBlack,
  HeartGrey,
  HeartWhite,
} from '@y2/mango/components/animation';
import { useEffectWithoutFirstRender } from '@y2/hooks/use-effect-without-first-render';

type AnimationProps = ComponentProps<typeof HeartBlack>;
type Direction = AnimationProps['direction'];
type AnimationComp = (props: AnimationProps) => JSX.Element;

const COLORS = ['black', 'grey', 'white'] as const;
export type Colors = (typeof COLORS)[number];

const LikeIcons: { [key in Colors]: AnimationComp } = {
  black: HeartBlack,
  grey: HeartGrey,
  white: HeartWhite,
};

type Props = Omit<AnimationProps, 'direction'> & {
  isLiked?: boolean;
  color: Colors;
};

export const LikeIcon = ({
  isLiked,
  color,
  ...otherAnimationsProps
}: Props) => {
  const [direction, setDirection] = useState<Direction>(
    isLiked ? 'freezeAtLast' : 'freezeAtFirst',
  );
  useEffectWithoutFirstRender(() => {
    setDirection(isLiked ? 'playToLast' : 'playToFirst');
  }, [isLiked]);
  const Component = LikeIcons[color];

  return <Component direction={direction} {...otherAnimationsProps} />;
};
