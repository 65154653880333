import { categories } from '@y2/categories';
import type { ParsedUrlQuery } from 'querystring';
import { analytics } from '@y2/google-tag-manager/v2/synchronized';
import { replaceSpacesWithUnderscoresWithDefault } from '@y2/analytics-common-events/analytics.utils';
import type { PageType } from '@y2/analytics';

const {
  realEstate: {
    subCategories: { yad1 },
    id,
  },
} = categories;

export const pushHeaderUserItemEvent = (label: string) => {
  analytics.pushEvent({
    EventName: 'navigation',
    EventDetail: 'header',
    EventText: replaceSpacesWithUnderscoresWithDefault(label),
    EventGroup: 'yad1_interactions',
  });
};

export const pushNewPropertiesEvent = (label: string) => {
  analytics.pushEvent({
    EventName: 'new_properties',
    EventDetail: 'null',
    EventText: replaceSpacesWithUnderscoresWithDefault(label),
    EventGroup: 'yad1_interactions',
  });
};

export const pushFeedPageEvent = (
  pageType: PageType,
  totalResultsNum: number,
  userId?: string,
  pageNumber = '1',
) =>
  analytics.pushPageEvent({
    PageType: pageType,
    Section: 'yad1',
    FeedName: 'main_feed',
    PageNumber: pageNumber,
    TotalResultsNum: String(totalResultsNum),
    Category: String(yad1.id),
    SubCategory: String(id),
    ...(userId && { UserID: userId }),
  });

export const pushPageEvent = (pageType: PageType, userId?: string) =>
  analytics.pushPageEvent({
    PageType: pageType,
    Section: 'yad1',
    Category: String(yad1.id),
    SubCategory: String(id),
    ...(userId && { UserID: userId }),
  });

export const pushYadataClickEvent = () => {
  analytics.pushEvent({
    EventName: 'transfer_yadata',
    EventDetail: 'null',
    EventText: 'null',
    EventGroup: 'yad1_interactions',
  });
};

export const pushGrayProjectClickEvent = (projectId: string) => {
  analytics.pushEvent({
    EventName: 'gray_minisite',
    EventDetail: 'null',
    EventText: 'null',
    EventGroup: 'yad1_interactions',
    ProjectID: projectId,
  });
};

export const pushCategoryClickEvent = (category: string) => {
  analytics.pushEvent({
    EventName: 'sub_category_tab_clicked',
    EventDetail: category,
    EventText: 'null',
    EventGroup: 'yad1_interactions',
  });
};

export const pushSearchEvent = (category: string, searchTerm = 'null') => {
  analytics.pushEvent({
    EventName: 'search',
    EventDetail: category,
    EventText: 'null',
    EventGroup: 'yad1_interactions',
    SearchTerm: searchTerm,
  });
};

export const pushLikePojectEvent = (
  detail: 'map' | 'feed' | 'carousel',
  projectId: string,
) => {
  analytics.pushEvent({
    EventName: 'like',
    EventDetail: detail,
    EventText: 'null',
    EventGroup: 'yad1_interactions',
    ProjectID: projectId,
  });
};

export const pushSearchDevelopersEvent = (
  category: string,
  searchTerm = 'null',
) => {
  analytics.pushEvent({
    EventName: 'search',
    EventDetail: category,
    EventText: searchTerm,
    EventGroup: 'yad1_interactions',
    SearchTerm: searchTerm,
  });
};
