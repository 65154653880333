import { GoogleTagManagerScript } from '@y2/google-tag-manager';
import { ErrorBoundary } from 'react-error-boundary';
import { ReactQueryProvider } from '@y2/react-query';
import { BrowserSupport } from '@y2/browser-support';
import { Accessibility } from '@y2/accessibility';
import { BoundaryFallback } from './_error.page';
import { CohortSetup } from '@y2/cohort';
import { usePushUuidEventOnLoad } from '@y2/google-tag-manager/update-uuid-event/use-push-uuid-on-load';
import { BrazeProvider, BrazeSetupReactQuery } from '@y2/braze';
import 'swiper/swiper-bundle.min.css';
import { AppProps } from 'next/app';
import env from '@y2/environments';
import Head from 'next/head';
import React from 'react';
import './root.scss';

const Yad1App = ({ Component, pageProps }: AppProps) => {
  usePushUuidEventOnLoad();

  return (
    <ReactQueryProvider dehydratedState={pageProps.dehydratedState}>
      <Head>
        <link rel="icon" href={`${env.assets.basePath}/common/logo-yad1.svg`} />
        <meta name="cHash" content={env.commit} />
      </Head>
      <ErrorBoundary FallbackComponent={BoundaryFallback}>
        <BrazeProvider>
          <main className="app">
            <Component {...pageProps} />
          </main>
          <BrazeSetupReactQuery />
        </BrazeProvider>
      </ErrorBoundary>
      <GoogleTagManagerScript environment={env.name} />
      <Accessibility />
      <CohortSetup />
      <BrowserSupport />
    </ReactQueryProvider>
  );
};

export default Yad1App;
