import {
  UserDropContainer,
  UserDropItem,
  UserDropChatButton,
  pushEnterChatSideMenuAnalitycsEvent,
} from '@y2/header';
import { useUserDetails, useLogout } from '@y2/auth-common/user-details-query';
import { goToLogin } from '@y2/auth-common/helpers';
import { useFavorites } from '@y2/favorites/react-query/use-favorites';
import { useLatestSearchesCount } from '@y2/latest-searches/react-query-hooks';
import { useMyAlertsCount } from '@y2/my-alerts-common/react-query-hooks';
import { pushHeaderUserItemEvent } from '../../../features/analytics/feed-events';
import texts from './header-user-drop.texts.json';

export const HeaderUserDrop = () => {
  const logout = useLogout();
  const { data: user } = useUserDetails();
  const isLoggedIn = !!user;
  const { data: favorites } = useFavorites(isLoggedIn);
  const { data: latestSearchesCount } = useLatestSearchesCount();
  const { data: alertsCount } = useMyAlertsCount(isLoggedIn);

  const handleGoToLogin = () => {
    if (!isLoggedIn) {
      goToLogin();
    }
  };

  const handleLogout = () => {
    logout.mutate();
  };

  return (
    <UserDropContainer
      onClick={handleGoToLogin}
      onLogoutClick={handleLogout}
      userInitials={user?.initials}
      userFullName={user?.fullName}
      theme="yad1"
      userOptions={user && <UserDropItem id="enterPersonal" />}
      options={
        <>
          <UserDropChatButton
            isUserLoggedIn={isLoggedIn}
            isMobileOnly
            sendAnalytics={pushEnterChatSideMenuAnalitycsEvent}
          />
          <UserDropItem
            id="favorites"
            isMobileOnly
            count={favorites?.length}
            onClick={() => pushHeaderUserItemEvent(texts.favoriteAds)}
          />
          <UserDropItem
            id="lastSearches"
            count={latestSearchesCount}
            onClick={() => pushHeaderUserItemEvent(texts.latestSearches)}
          />
          <UserDropItem
            id="alerts"
            count={alertsCount}
            onClick={() => pushHeaderUserItemEvent(texts.alerts)}
          />
          <UserDropItem id="customerServicePortal" isMobileOnly />
        </>
      }
    />
  );
};
