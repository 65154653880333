/* eslint-disable max-statements */
/* eslint-disable max-lines-per-function */
import { QueryClient } from '@tanstack/react-query';
import * as localStorage from '../../service/local-storage';
import {
  remove,
  add,
  addRecommerce,
  removeRecommerce,
} from '../../service/anonymous-user';
import { legacyFeedClient, LikedItemData } from '../clients';
import {
  FetchFavorites,
  RemoveMutationOptions,
  AddMutationOptions,
} from './types';
import { log } from '@y2/log';
import { FavoriteItem, fromApiData } from '../../service';
import { isAxiosError, STATUS_CODES } from '@y2/api-clients';
import {
  isCreateBeforeSecond,
  mapRecommerceProductToFavoriteItem,
} from '../../components/favorites-item/helpers';
import { recommerceFeedClient } from '@y2/api-clients/recommerce-feed';

const recommerceClient = recommerceFeedClient();

export const getQueryKey = () => ['user', 'favorites', 'anonymous'];

export const fetchFavorites: FetchFavorites = async () => {
  try {
    const resRecommerceFromLocalStorage = localStorage.getAllRecommerce();

    const hasRecommerce = resRecommerceFromLocalStorage.length;
    let recommerceItems: FavoriteItem[] = [];
    if (hasRecommerce) {
      const mappedLikedItemsRecommerce = resRecommerceFromLocalStorage.reduce(
        (allFavorites, favorite) => ({
          ...allFavorites,
          [favorite.adId]: favorite,
        }),
        {} as Record<number, localStorage.LikedItemStorage>,
      );
      const recommerceTokens = Object.keys(mappedLikedItemsRecommerce);
      const recommerceRes = await recommerceClient.getProductsByShopifyIds(
        recommerceTokens.map(Number),
      );

      recommerceItems = mapRecommerceProductToFavoriteItem(
        recommerceRes,
        mappedLikedItemsRecommerce,
      );
    }

    const resFromLocalStorage = localStorage.getAll();

    const favorites = resFromLocalStorage.reduce(
      (allFavorites, favorite) => ({
        ...allFavorites,
        [String(favorite.adId)]: favorite,
      }),
      {} as Record<string, localStorage.LikedItemStorage>,
    );

    const regularItemsTokens = Object.keys(favorites);

    if (!regularItemsTokens?.length) {
      return recommerceItems;
    }

    const feedRes = await legacyFeedClient.getMany(regularItemsTokens);

    const regularItems = feedRes.data.map((item) =>
      fromApiData(item, favorites[item.id]?.updatedAt),
    );

    return [...recommerceItems, ...regularItems].sort((first, second) =>
      isCreateBeforeSecond(first.createdAt, second.createdAt),
    );
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response?.status === STATUS_CODES.GATEWAY_TIMEOUT
    ) {
      log.info('failed to fetch anonymous local favorites');
    } else {
      log.error(err, 'failed to fetch anonymous local favorites');
    }
    throw err;
  }
};

export const getRemoveMutationOptions = (
  queryClient: QueryClient,
): RemoveMutationOptions => ({
  mutationFn: ({ token, isRecommerce }) => {
    if (isRecommerce) {
      return Promise.resolve(removeRecommerce(token));
    } else {
      return Promise.resolve(remove(token));
    }
  },
  onSuccess: (data, { token }) => {
    const queryKey = getQueryKey();
    const favorites = queryClient.getQueryData<LikedItemData[]>(queryKey);

    if (favorites) {
      const newFavorites = favorites.filter((item) => item.id !== token);
      queryClient.setQueryData(queryKey, newFavorites);
    }
  },
});

export const getAddMutationOptions = (
  queryClient: QueryClient,
): AddMutationOptions => ({
  mutationFn: async ({ token, isRecommerce }) => {
    if (isRecommerce) {
      const recommerceRes = await recommerceClient.getProductsByShopifyIds([
        Number(token),
      ]);
      const [recommerceItem] =
        mapRecommerceProductToFavoriteItem(recommerceRes);
      return recommerceItem;
    } else {
      return legacyFeedClient.get(token).then((res) => fromApiData(res.data));
    }
  },
  onSuccess: (itemData, { token, isRecommerce }) => {
    if (isRecommerce) {
      addRecommerce(token);
    } else {
      add(token);
    }
    const queryKey = getQueryKey();
    const favorites = queryClient.getQueryData<LikedItemData[]>(queryKey) || [];

    queryClient.setQueriesData(queryKey, [...favorites, itemData]);
  },
  onError: (err) => {
    log.error(err, 'failed to post liked click on favorites anonymous');
    throw err;
  },
});
