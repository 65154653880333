export type LikedItemStorage = {
  adId: string;
  comment: string;
  updatedAt: string;
};

const KEY = 'likedItemsIds';
const RECOMMERCE_KEY = 'recommerceLikedItemsIds';

export function getAll(): LikedItemStorage[] {
  if (!localStorage) return [];
  const jsonData = localStorage.getItem(KEY);

  if (!jsonData || jsonData === 'undefined') {
    return [];
  }

  return JSON.parse(jsonData) as LikedItemStorage[];
}

export function setAll(likesItems: LikedItemStorage[]) {
  localStorage.setItem(KEY, JSON.stringify(likesItems));
}

export function getAllRecommerce(): LikedItemStorage[] {
  if (!localStorage) return [];

  const jsonData = localStorage.getItem(RECOMMERCE_KEY);
  if (!jsonData || jsonData === 'undefined') {
    return [];
  }
  return JSON.parse(jsonData) as LikedItemStorage[];
}

export function setAllRecommerce(likesItems: LikedItemStorage[]) {
  localStorage.setItem(RECOMMERCE_KEY, JSON.stringify(likesItems));
}

export function removeAll() {
  localStorage.removeItem(KEY);
}

export function removeAllRecommerce() {
  localStorage.removeItem(RECOMMERCE_KEY);
}
