import React from 'react';
import userDropItemStyles from './user-drop-item.module.scss';
import optionIconStyles from '../options-collection/option-icon.module.scss';
import noop from 'lodash/noop';
import cn from 'classnames';
import Option from '../option/option';
import { ChatIcon } from '@y2/mango/components/icons';
import texts from '../options-collection/default-options.text.json';
import { useUnreadChatMessagesCount } from '../../hooks';
import { ChatMessageNotification } from '@y2/socket-clients/sendbird-updates/types';

type Props = {
  isUserLoggedIn: boolean;
  isMobileOnly?: boolean;
  onClick?: () => void;
  sendAnalytics?: () => void;
  onNewMessage?: (message: ChatMessageNotification) => void;
};

const CHAT_INBOX_URL = '/chat/v2/inbox?serviceInitiator=Side%20menu';

export const UserDropChatButton: React.VFC<Props> = ({
  isUserLoggedIn,
  isMobileOnly,
  onClick = noop,
  sendAnalytics,
  onNewMessage = noop,
}) => {
  const unreadChatMessagesCount = useUnreadChatMessagesCount(
    isUserLoggedIn,
    onNewMessage,
  );

  return (
    <li
      className={cn(
        userDropItemStyles['option-item'],
        isMobileOnly && userDropItemStyles['is-mobile-only'],
      )}
    >
      <Option
        icon={<ChatIcon className={optionIconStyles.optionIcon} />}
        text={texts.CHAT}
        url={CHAT_INBOX_URL}
        count={unreadChatMessagesCount}
        onClick={onClick}
        sendAnalytics={sendAnalytics}
        data-testid="chat-option"
      />
    </li>
  );
};

export default UserDropChatButton;
