/* eslint-disable no-underscore-dangle */
/* eslint-disable camelcase */
import axios from '../http-client';
import env from '@y2/environments';

export type LikedItemData = {
  id: string;
  image: string;
  img_url: string;
  images_urls: string[];
  price: string;
  row_1: string;
  row_2: string;
  city: string;
  comment: string;
  cat_id: string;
  subcat_id: string;
  CatID: string;
  SubCatID: string;
  is_kones?: boolean;
  date_added: string;
};

export const createFeedClient = (baseURL = env.routes.api.old) => {
  const client = axios.create({
    baseURL: `${baseURL}/feed`,
    withCredentials: true,
  });

  return {
    get: (token: string) => client.get<LikedItemData>(`/${token}`),
    getMany: (tokens: string[]) =>
      client.get<LikedItemData[]>('', {
        params: { items: tokens.join(',') },
      }),
  };
};

export type FeedClient = ReturnType<typeof createFeedClient>;
