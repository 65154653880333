export type Themes = 'dark' | 'light' | 'blue';

export const themesColors: {
  [key in Themes]: {
    background: string;
    border: string;
    color: string;
    '--arrow-color': string;
    '--arrow-border': string;
  };
} = {
  dark: {
    background: '#363636',
    border: '1px solid #363636',
    color: '#ffffff',
    '--arrow-color': '#363636',
    '--arrow-border': '#363636',
  },
  light: {
    background: '#ffffff',
    border: '1px solid #eeeeee',
    color: '#363636',
    '--arrow-color': '#ffffff',
    '--arrow-border': '#eeeeee',
  },
  blue: {
    background: '#3578e5',
    border: '1px solid #3578e5',
    color: '#ffffff',
    '--arrow-color': '#3578e5',
    '--arrow-border': '#3578e5',
  },
};
