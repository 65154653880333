import {
  useQuery,
  UseQueryOptions,
  useQueryClient,
  useMutation,
  QueryOptions,
} from '@tanstack/react-query';
import { NextApiRequest } from 'next';
import { authClient, UserData } from '@y2/api-clients/auth';
import { isAxiosError } from '@y2/api-clients';
import { log } from '@y2/log';

type Client = ReturnType<typeof authClient>;

const client: Client = authClient();

const getQueryKey = () => ['user', 'details'];

const userDetailsAdapter = (user: UserData) => {
  const firstName = user?.FirstName || '';
  const lastName = user?.LastName || '';
  const initials = `${firstName.charAt(0)}${lastName.charAt(0)}`;

  return {
    ...user,
    firstName,
    lastName,
    email: user?.Email,
    phoneNumber: user?.Mobile,
    initials,
    fullName: `${firstName} ${lastName}`,
    id: String(user?.UserID),
    birthday: user?.Birthday,
    phone: user?.Mobile,
    subscribeMailingList: user?.MailingList,
  };
};

export type UserDetails = ReturnType<typeof userDetailsAdapter>;

const HTTP_STATUS_UNAUTHORIZED = 401;

export const getFetchMethod =
  (client: Client, req?: NextApiRequest) => async () => {
    try {
      const headers = req && req['headers'];
      const res = await client.getUserDetails(headers);

      return userDetailsAdapter(res.data.data.user);
    } catch (err) {
      if (isUnauthorized(err)) {
        return null;
      } else {
        /** remove log.error if log is reported in the component */
        log.error(err, 'failed to fetch user-details on getUserDetails');
        /** re-throw for the component, const { data, error } = useUserDetails */
        throw err;
      }
    }
  };

const isUnauthorized = (error: unknown) =>
  isAxiosError(error) &&
  error.response &&
  error.response.status === HTTP_STATUS_UNAUTHORIZED;

type CustomOptions = Omit<
  QueryOptions<UserDetails | null>,
  'queryKey' | 'queryFn'
> & {
  enabled?: boolean;
};

const isBrowser = typeof window !== 'undefined';

export const getUserDetailsOptions = (
  customOptions?: CustomOptions,
  req?: NextApiRequest,
): UseQueryOptions<UserDetails | null> => {
  const fetchUserDetails = getFetchMethod(client, req);

  return {
    queryKey: getQueryKey(),
    queryFn: fetchUserDetails,
    enabled: customOptions?.enabled || isBrowser,
    ...(customOptions || {}),
  };
};

export const useUserDetails = (customOptions?: CustomOptions) =>
  useQuery(getUserDetailsOptions(customOptions));

export function useLogout() {
  const queryClient = useQueryClient();

  return useMutation({
    mutationFn: client.logout,
    onSuccess: () => {
      queryClient.setQueriesData(getQueryKey(), null);
    },
  });
}
