import { LikeButton } from '../../like-button';
import { FavoriteItem } from '../../../../service';
import styles from './favorites-drop-item.module.scss';
import text from './favorites-drop-item.text.json';
import { InfoColumn } from './info-column/info-column';
import { PriceColumn } from './price-column/price-column';
import {
  getItemPageUrl,
  getImageUrl,
} from '../../../../components/favorites-item/helpers';
import { useRouter } from 'next/router';

type Props = {
  item: FavoriteItem;
  onDislike: () => void;
  appName?: string;
  index: number;
};

export const FavoriteDropItem = ({
  item,
  onDislike,
  appName,
  index,
}: Props) => {
  const { pathname } = useRouter();
  const openedFrom = appName || pathname.includes('/item') ? 'item' : 'feed';

  return (
    <li
      className={styles.box}
      data-testid="favorite-drop-item"
      data-nagish="header-favorite-item"
    >
      <LikeButton
        token={item.id}
        onDislike={onDislike}
        color="black"
        className={styles.likePosition}
        hasFrame
      />
      <a
        className={styles.link}
        href={getItemPageUrl(item, openedFrom, index)}
        data-nagish="header-favorite-link"
      >
        <div className={styles.imgContainer}>
          <img
            className={styles.img}
            src={getImageUrl(item.images.main, item.images.additional)}
            alt={text.FAVORITES_IMAGE}
          />
        </div>
        <InfoColumn
          title={item.title}
          subTitle={item.subTitle}
          comment={item.comment}
        />
        <PriceColumn price={item.price} />
      </a>
    </li>
  );
};
