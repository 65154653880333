import axios, { ResponseBody } from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import { categories } from '@y2/categories';
const serviceName = 'my-alerts';

type Field = {
  label: string;
  text: string;
  value: string;
  from?: number;
  to?: number;
};

export type AlertType = 'immediate' | 'daily';

export type CreateAlertResponse = ResponseBody<{ alert: Alert }>;

export type CreateAlertBodyReq = {
  type: AlertType;
  url: string;
};

export type CreateAlertPaymentResponse = ResponseBody<{
  creditCard: string;
  bit: string;
}>;

export type CreateAlertPaymentBodyReq = {
  type: AlertType;
  url: string;
  paymentSuccessUrl: string;
  paymentFailUrl: string;
};

export type Alert = {
  id: string;
  fields: Record<string, Field>;
  active: boolean;
  type: AlertType;
  title: string;
  url: string;
  categoryId: typeof categories.vehicles.id | typeof categories.realEstate.id;
};

type AlertsCountResponse = ResponseBody<{ alertsCount?: number }>;
export type AlertListResponse = ResponseBody<{ alerts: Alert[] }>;

/**
 * client for `my-alerts` service
 * @see https://gw.yad2.io/my-alerts/api-docs/#
 */
export const myAlertsClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    count: () => client.get<AlertsCountResponse>('/alert/count'),
    alerts: () => client.get<AlertListResponse>('/alert'),
    create: (request: CreateAlertBodyReq) =>
      client.post<CreateAlertResponse>('/alert', request),
    createPayment: (request: CreateAlertPaymentBodyReq) =>
      client.post<CreateAlertPaymentResponse>('/alert', request),
  };
};

export type AlertsClient = ReturnType<typeof myAlertsClient>;
