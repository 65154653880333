import { categories } from '@y2/categories';

const CATEGORY_DEFAULT_IMAGES: { [key: number]: string } = {
  [categories.vehicles.id]:
    '//assets.yad2.co.il/yad2site/y2assets/images/pages/feed/feed_car_placeholder_small.png',
  [categories.realEstate.id]:
    '//assets.yad2.co.il/yad2site/y2assets/images/pages/feed/feed_re_placeholder_small.png',
};

export const GENERIC_DEFAULT_IMAGE =
  '//assets.yad2.co.il/yad2site/y2assets/images/pages/feed/feed_img_placeholder_small.jpg';

export const getDefaultImage = (catId: number): string => {
  const categoryDefaultImg = CATEGORY_DEFAULT_IMAGES[catId];

  return categoryDefaultImg || GENERIC_DEFAULT_IMAGE;
};
