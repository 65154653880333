import React, { FC, ReactNode, useState, useRef } from 'react';
import styles from './tooltip.module.scss';
import cn from 'classnames';
import { useUniqueIdEnforcer } from '../checkbox/use-unique-id-enforcer';
import { Themes, themesColors } from './consts';
import { Placement, getPointerDirection } from './placement-tools';
import { useOpen, Status } from './use-open';
import { CloseButton } from './close-button/close-button';

type DivAttributes = Omit<
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>,
  'content'
>;

export type { Placement };

export type TooltipProps = DivAttributes & {
  content: ReactNode;
  children?: ReactNode;
  placement?: Placement;
  hasCloseButton?: boolean;
  onCloseClick?: React.MouseEventHandler<HTMLButtonElement>;
  status?: Status;
  className?: string;
  tooltipClassName?: string;
  theme?: Themes;
  disableAnimation?: boolean;
  closeDelayTime?: number;
};

export const Tooltip: FC<TooltipProps> = ({
  content,
  children,
  className,
  onCloseClick,
  placement = 'bottom',
  hasCloseButton = false,
  status = 'useHover',
  tooltipClassName,
  theme = 'dark',
  disableAnimation,
  closeDelayTime,
  ...otherAttributes
}) => {
  const [id, setId] = useState<string | undefined>(otherAttributes.id);
  useUniqueIdEnforcer({ id, setId, prefix: 'tooltip' });
  const ref = useRef(null);
  const { isOpen, placementForClass, onHover } = useOpen(
    status,
    placement,
    ref,
    closeDelayTime,
  );
  const pointDirection = getPointerDirection(placementForClass);

  return (
    <div
      className={cn(styles.box, className)}
      data-testid="tooltip"
      aria-describedby={id}
      onMouseEnter={() => onHover('enter')}
      onMouseLeave={() => onHover('leave')}
      {...otherAttributes}
    >
      {children}
      {content && (
        <div
          role="tooltip"
          id={id}
          ref={ref}
          className={cn(
            styles.tooltip,
            tooltipClassName,
            styles[placementForClass],
            styles[pointDirection],
            isOpen && styles.open,
            disableAnimation && styles.disableAnimation,
            hasCloseButton && styles.closeButtonPadding,
          )}
          style={themesColors[theme]}
        >
          {hasCloseButton && <CloseButton onCloseClick={onCloseClick} />}
          {content}
        </div>
      )}
    </div>
  );
};
