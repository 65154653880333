import React from 'react';
import styles from './categories-list.module.scss';
import {
  ApartmentsForRentCategory,
  ApartmentsForSaleCategory,
  NewApartmentsCategory,
  PopularRentPagesCategory,
} from './categories';

export const CategoriesList = () => {
  return (
    <nav
      className={styles.categoriesListNav}
      data-nagish="footer-categories-links-menu"
    >
      <ul
        className={styles.categoriesList}
        data-nagish="footer-categories-list"
      >
        <NewApartmentsCategory />
        <ApartmentsForSaleCategory />
        <ApartmentsForRentCategory />
        <PopularRentPagesCategory />
      </ul>
    </nav>
  );
};
