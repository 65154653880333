/* istanbul ignore file */
import React from 'react';
import styles from './user-drop-list.module.scss';
import LogoutOption from '../options-collection/logout-option';

type Props = {
  onLogoutClick: () => void;
  options?: React.ReactNode;
  userFullName?: string;
  userOptions?: React.ReactNode;
};

// eslint-disable-next-line custom-rules/only-return-jsx-declaration
const UserOptionsList: React.VFC<Props> = ({
  onLogoutClick,
  userFullName = '',
  userOptions = null,
  options = null,
}) => {
  const optionsHeader = userFullName ? (
    <h3 className={styles.fullNameRow} data-nagish="header-user-options-title">
      {userFullName}
    </h3>
  ) : null;
  const userContent =
    optionsHeader || userOptions ? (
      <ul
        className={styles['user-content-group']}
        data-nagish="header-user-content-list"
      >
        <li
          className={styles['header-row']}
          data-nagish="header-user-content-item"
        >
          {optionsHeader}
        </li>
        {userOptions}
      </ul>
    ) : null;
  const disconnectRow = userFullName ? (
    <div className={styles['disconnect-row']}>
      <LogoutOption onClick={onLogoutClick} />
    </div>
  ) : null;

  return (
    <>
      {userContent}
      <ul
        className={styles['options-list']}
        data-nagish="header-user-options-list"
      >
        {options}
      </ul>
      {disconnectRow}
    </>
  );
};

export default UserOptionsList;
