import styles from './favorites-drop-button.module.scss';
import { HeartIcon } from '@y2/mango/components/icons';
import text from './favorites-drop-button.text.json';
import { Badge } from '@y2/mango/components/badge';
import env from '@y2/environments';

type Props = {
  likedCount: number;
};

export const FavoritesButton = ({ likedCount }: Props) => (
  <a
    href={`${env.routes.mainSite}/my-favorites`}
    className={styles.dropButton}
    data-nagish="header-favorites-button"
  >
    <span className={styles.iconBox}>
      <HeartIcon className={styles.icon} data-testid="favorites-heart-icon" />
      {likedCount ? (
        <Badge
          type={'numeric'}
          variant={'orange'}
          size={'small'}
          label={String(likedCount)}
          ariaLabel={`${text.AVAILABLE_ITEMS} ${likedCount}`}
          className={styles.count}
        />
      ) : null}
    </span>
    <span className={styles.text}>{text.LIKED_ITEMS}</span>
  </a>
);
