import { useState, useEffect } from 'react';
import {
  useFavorites,
  useFavoritesRemove,
  useFavoritesAddition,
} from '../../use-favorites';
import { FavoriteItem } from '../../../service';
import { useUserDetails } from '@y2/auth-common/user-details-query';
import { postWebViewEvent } from '@y2/react-native-utils';

const isInFavorites = (favorites: FavoriteItem[], token: string) =>
  !!favorites.find((item) => item.id === token);

// eslint-disable-next-line max-lines-per-function
export const useLikeStatus = (
  token: string,
  isRecommerce = false,
  onError?: () => void,
) => {
  const { data: user } = useUserDetails();
  const isLogged = !!user;
  const { data: favorites } = useFavorites(isLogged);
  const [isSuspense, setIsSuspense] = useState<boolean>(false);
  const [isLiked, setIsLiked] = useState<boolean>(
    isInFavorites(favorites || [], token),
  );

  useEffect(() => {
    setIsLiked(isInFavorites(favorites || [], token));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [favorites]);

  const mutateRemove = useFavoritesRemove(isLogged);
  const mutateAddition = useFavoritesAddition(isLogged);

  return {
    isLiked,
    update: (isLiked: boolean) => {
      if (isSuspense) return;
      setIsSuspense(true);

      const handleError = (revertedStatus: boolean) => {
        setIsLiked(revertedStatus);
        onError?.();
      };

      if (isLiked) {
        setIsLiked(true);

        mutateAddition.mutate(
          { token, isRecommerce },
          {
            onError: () => handleError(false),
            onSettled: () => setIsSuspense(false),
          },
        );
        postWebViewEvent({
          event: 'onFavoriteAdded',
          shareData: {
            token,
          },
        });
      } else {
        setIsLiked(false);
        mutateRemove.mutate(
          { token, isRecommerce },
          {
            onError: () => handleError(true),
            onSettled: () => setIsSuspense(false),
          },
        );
        postWebViewEvent({
          event: 'onFavoriteRemoved',
          shareData: {
            token,
          },
        });
      }
    },
  };
};
