export const PAGE_SHOW = 'pageshow';
export const SOCKET_PATH = '/sendbird_updates/socket.io';

export const CHAT_SOCKET_EVENTS = {
  ON_INCOMING: 'send-message',
  ON_CONNECT_ERROR: 'connect_error',
  ON_ERROR: 'error',
};

export const EVENT_HANDLER_SWITCH = {
  ATTACH_HANDLERS: 'on',
  DETACH_HANDLERS: 'off',
} as const;

export const LOGIN_ERRORS = {
  REFRESH_TOKEN: 'refresh token',
  ACCESS_TOKEN: 'access_token',
  PING_TIMEOUT: 'ping timeout',
};
