import styles from './no-logged-items.module.scss';
import text from './no-logged-items.json';

type Props = {
  isLoggedIn: boolean;
  likedItemsCount: number;
};

const getNotLoggedInText = (likedItemsCount: number): string => {
  let openLine = '';
  if (likedItemsCount) {
    const [firstPart, secondPart] = text.LOG_WITH_LIST;
    openLine = [firstPart, `(${String(likedItemsCount)})`, secondPart].join(
      ' ',
    );
  } else {
    openLine = text.LOG_EMPTY_LIST;
  }

  return [openLine, text.LOG_TO_LIKE].join(' ');
};

export const NoLoggedItems = ({ isLoggedIn, likedItemsCount }: Props) => (
  <li
    className={styles.noteBox}
    data-testid="no-logged-items"
    data-nagish="favorite-no-items-note"
  >
    <span className={styles.title}>{text.LIKED_ITEMS}</span>
    <span className={styles.text}>
      {isLoggedIn ? text.EMPTY_LIST : getNotLoggedInText(likedItemsCount)}
    </span>
  </li>
);
