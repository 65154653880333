import React, { ReactNode, useRef } from 'react';
import {
  Hydrate,
  QueryClient,
  QueryClientProvider,
} from '@tanstack/react-query';
import { ReactQueryDevtools } from '@tanstack/react-query-devtools';
import env from '@y2/environments';

const isLocal = env.name === 'local';

type ProviderProps = {
  /**
   * dehydratedState should be in pageProps in your _app.tsx
   * use getDehydratedState(..) from this lib in your getServerSideProps method
   */
  dehydratedState: any;
  children: ReactNode;
  staleTime?: number;
};

export const ReactQueryProvider = ({
  dehydratedState,
  children,
  staleTime = Infinity,
}: ProviderProps) => {
  const clientRef = useRef(
    new QueryClient({
      defaultOptions: {
        queries: {
          staleTime,
        },
      },
    }),
  );

  return (
    <QueryClientProvider client={clientRef.current}>
      <Hydrate state={dehydratedState}>{children}</Hydrate>
      {isLocal && <ReactQueryDevtools initialIsOpen={false} />}
    </QueryClientProvider>
  );
};
