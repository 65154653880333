import React from 'react';
import styles from './option-icon.module.scss';
import Option from '../option/option';
import cn from 'classnames';
import {
  UserIcon,
  EditIcon,
  StarIcon,
  HomeIcon,
  HistoryIcon,
  HeartIcon,
  NotificationIcon,
  CustomerSupportPortalIcon,
} from '@y2/mango/components/icons';
import texts from './default-options.text.json';
import env from '@y2/environments';

export const defaultOptionsIds = [
  'enterPersonal',
  'editPersonal',
  'myAds',
  'alerts',
  'lastSearches',
  'homePage',
  'favorites',
  'customerServicePortal',
] as const;

export type DefaultOptionId = (typeof defaultOptionsIds)[number];

type DefaultOptions = {
  [key in DefaultOptionId]: (
    onClick?: () => void,
    count?: number,
    isUserLoggedIn?: boolean,
  ) => React.ReactNode;
};

export const defaultOptionsComponents: DefaultOptions = {
  enterPersonal: (onClick, count) => (
    <Option
      icon={
        <UserIcon className={cn(styles.optionIcon, styles.removeFromMobile)} />
      }
      text={texts.ENTER_PERSONAL}
      url={`${env.routes.mainSite}/my-ads`}
      customStyle={'hoisted'}
      count={count}
      onClick={onClick}
      className={styles.personal}
      data-testid="enterPersonal-option"
    />
  ),
  editPersonal: (onClick, count) => (
    <Option
      icon={
        <EditIcon className={cn(styles.optionIcon, styles.removeFromMobile)} />
      }
      text={texts.EDIT_PERSONAL}
      url={`${env.routes.mainSite}/my-info/profile`}
      customStyle={'hoisted'}
      count={count}
      onClick={onClick}
      data-testid="editPersonal-option"
    />
  ),
  myAds: (onClick, count) => (
    <Option
      icon={<StarIcon className={styles.optionIcon} />}
      text={texts.MY_ADS}
      url={`${env.routes.mainSite}/my-ads`}
      count={count}
      onClick={onClick}
      data-testid="myAds-option"
    />
  ),
  alerts: (onClick, count) => (
    <Option
      icon={<NotificationIcon className={styles.optionIcon} />}
      text={texts.ALERTS}
      url={`${env.routes.mainSite}/my-alerts`}
      count={count}
      onClick={onClick}
      data-testid="alerts-option"
    />
  ),
  lastSearches: (onClick, count) => (
    <Option
      icon={<HistoryIcon className={styles.optionIcon} />}
      text={texts.LAST_SEARCHES}
      url={`${env.routes.mainSite}/latestsearches`}
      count={count}
      onClick={onClick}
      data-testid="lastSearches-option"
    />
  ),
  homePage: (onClick, count) => (
    <Option
      icon={<HomeIcon className={styles.optionIcon} />}
      text={texts.BACK_HOME}
      url={env.routes.mainSite}
      count={count}
      onClick={onClick}
      data-testid="homePage-option"
    />
  ),
  favorites: (onClick, count) => (
    <Option
      icon={<HeartIcon className={styles.optionIcon} />}
      text={texts.FAVORITES}
      url={`${env.routes.mainSite}/my-favorites`}
      count={count}
      onClick={onClick}
      data-testid="favorites-option"
    />
  ),
  customerServicePortal: (onClick) => (
    <Option
      icon={<CustomerSupportPortalIcon className={styles.optionIcon} />}
      text={texts.CUSTOMER_SERVICE_PORTAL}
      url={'https://service.yad2.co.il'}
      as={'a'}
      target="_blank"
      onClick={onClick}
      data-testid="customerServicePortal-option"
    />
  ),
};
