import { isWebView } from '@y2/react-native-utils';
import { useLayoutEffect, useState } from 'react';

export function useNativeApp() {
  const [isNativeApp, setIsNativeApp] = useState(false);

  useLayoutEffect(() => {
    const { userAgent } = window.navigator || {};
    setIsNativeApp(isWebView('REACT_NATIVE_APP', userAgent));
  }, []);

  return isNativeApp;
}
