import { useState, useRef } from 'react';
import cn from 'classnames';
import styles from './favorites-dropdown.module.scss';
import { FavoritesButton } from '../button';
import { useFavorites, useFavoritesRemove } from '../../../use-favorites';
import { usePopupViewportPosition } from '@y2/hooks/use-popup-viewport-position';
import { FavoritesDropList } from '../list';
import { FavoriteItem } from '../../../../service';
import noop from 'lodash/noop';

type DivAttributes = Omit<
  React.DetailedHTMLProps<
    React.InputHTMLAttributes<HTMLInputElement>,
    HTMLInputElement
  >,
  'onClick'
>;

type Props = DivAttributes & {
  isLoggedIn: boolean;
  appName?: string;
  onRemoveItem?: (item: FavoriteItem) => void;
  onClick?: () => void;
};

export const FavoritesDropMenu = ({
  isLoggedIn,
  onRemoveItem,
  className,
  appName,
  onClick = noop,
  ...otherDivAttributes
}: Props) => {
  const dropRef = useRef<HTMLDivElement>(null);
  const [isOpen, setIsOpen] = useState<boolean>(false);
  usePopupViewportPosition(dropRef, isOpen);
  const { data: favorites } = useFavorites(isLoggedIn);
  const removeItem = useFavoritesRemove(isLoggedIn);

  return (
    <div
      className={cn(styles.box, className)}
      onMouseEnter={() => setIsOpen(true)}
      onMouseLeave={() => setIsOpen(false)}
      onClick={onClick}
      {...otherDivAttributes}
      data-testid="favorites-dropdown-menu"
    >
      <FavoritesButton likedCount={favorites?.length || 0} />
      <div
        className={cn(styles.dropContent, isOpen && styles.open)}
        ref={dropRef}
      >
        <FavoritesDropList
          onRemoveItem={(item) => removeItem.mutate({ token: item.id })}
          favorites={favorites}
          isLoggedIn={isLoggedIn}
          appName={appName}
        />
      </div>
    </div>
  );
};
