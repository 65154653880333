import './types';

type WebViewEvent = {
  event: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
} & Record<string, any>;

const isBrowser = () => typeof window !== 'undefined';

export const postWebViewEvent = (eventArgs: WebViewEvent) => {
  const hasEnvForEvent = isBrowser() && window.ReactNativeWebView;
  if (hasEnvForEvent) {
    window.ReactNativeWebView?.postMessage(JSON.stringify(eventArgs));
  }
};
