import {
  useMutation,
  useQuery,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import * as logged from './logged';
import * as anonymous from './anonymous';
import { FavoriteItem } from '../../service';

export const getQueryKey = (isLoggedIn: boolean) =>
  isLoggedIn ? logged.getQueryKey() : anonymous.getQueryKey();

export const getOptions = (
  isLoggedIn: boolean,
): UseQueryOptions<FavoriteItem[]> => {
  const queryFn = isLoggedIn
    ? () => logged.fetchFavorites()
    : () => anonymous.fetchFavorites();

  const queryKey = getQueryKey(isLoggedIn);

  return {
    queryKey,
    queryFn,
  };
};

export const useFavorites = (isLoggedIn: boolean) =>
  useQuery(getOptions(isLoggedIn));

export const useFavoritesReset = () => {
  const queryClient = useQueryClient();

  return (isLoggedIn: boolean) => {
    const key = getQueryKey(isLoggedIn);
    queryClient.setQueryData(key, null);
  };
};

export const useFavoritesRemove = (isLoggedIn: boolean) => {
  const queryClient = useQueryClient();
  const options = isLoggedIn
    ? logged.getRemoveMutationOptions(queryClient)
    : anonymous.getRemoveMutationOptions(queryClient);

  return useMutation(options);
};

export const useFavoritesAddition = (isLoggedIn: boolean) => {
  const queryClient = useQueryClient();
  const options = isLoggedIn
    ? logged.getAddMutationOptions(queryClient)
    : anonymous.getAddMutationOptions(queryClient);

  return useMutation(options);
};

export { fetchRecommerceFavorites } from './logged';
