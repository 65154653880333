import classNames from 'classnames';
import styles from './profile-circle.module.scss';
import { ProfileElement } from './element/profile-element';

type Props = {
  isConnected: boolean;
  userProfileImg: string;
  userInitials: string;
  theme?: 'yad1' | 'primary';
};

export const ProfileCircle = ({
  userProfileImg,
  userInitials,
  isConnected,

  theme = 'primary',
}: Props) => (
  <span
    className={classNames(
      styles.circleArea,
      isConnected && styles.loggedIn,
      styles[theme],
    )}
  >
    <ProfileElement
      userProfileImg={userProfileImg}
      userInitials={userInitials}
    />
  </span>
);
