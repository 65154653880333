import { FavoriteDropItem } from '../item';
import { FavoriteItem } from '../../../../service';
import { AllFavoritesLinkRow } from './all-favorites-link-row/all-favorites-link-row';
import { NoLoggedItems } from './no-logged-items-note/no-logged-items';
import styles from './favorites-list.module.scss';

type Props = {
  favorites?: FavoriteItem[];
  onRemoveItem: (item: FavoriteItem) => void;
  isLoggedIn: boolean;
  appName?: string;
};

const MAX_ADS = 3;

export const FavoritesDropList = ({
  onRemoveItem,
  isLoggedIn,
  appName,
  favorites = [],
}: Props) => {
  const showNoteBox = !isLoggedIn || !favorites.length;
  const list = [...favorites].reverse().slice(0, MAX_ADS);

  return (
    <ul className={styles.box} data-nagish="header-favorite-list">
      {showNoteBox ? (
        <NoLoggedItems
          isLoggedIn={isLoggedIn}
          likedItemsCount={favorites?.length || 0}
        />
      ) : (
        <>
          {list.map((item, index) => (
            <FavoriteDropItem
              key={item.id}
              item={item}
              onDislike={() => onRemoveItem(item)}
              appName={appName}
              index={index}
            />
          ))}
          <AllFavoritesLinkRow />
        </>
      )}
    </ul>
  );
};
