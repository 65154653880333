/* eslint-disable camelcase */
import { LikedItemData } from '@y2/api-clients';
import { getDefaultImage } from './default-images';

export type FavoriteItem = {
  id: string;
  images: {
    main: string;
    additional: string[];
  };
  price: string;
  title: string;
  subTitle: string;
  city: string;
  comment: string;
  catId: number;
  subCatId: number;
  isKones: boolean;
  createdAt?: Date;
  isRecommerce?: boolean;
  urlIdentifier?: string;
};

function isImage(imgUrl: string): boolean {
  return /\.(jpg|jpeg|png|webp|avif|gif|svg)$/.test(imgUrl);
}

export function fromApiData(
  resData: LikedItemData,
  createdAt?: string,
): FavoriteItem {
  // prettier-ignore
  const {
    id,
    image,
    img_url,
    images_urls,
    price,
    row_1: title,
    row_2: subTitle,
    city,
    comment,
  } = resData;
  const catId = parseInt(resData.cat_id || resData.CatID);
  const subCatId = parseInt(resData.subcat_id || resData.SubCatID);
  const isKones = Boolean(resData.is_kones);
  const mainImg =
    [img_url, image].find((img) => isImage(img)) || getDefaultImage(catId);

  return {
    id,
    images: {
      main: mainImg,
      additional: images_urls || [],
    },
    price,
    title,
    subTitle,
    city,
    comment,
    catId,
    subCatId,
    isKones,
    createdAt: createdAt ? new Date(createdAt) : undefined,
  };
}
