import React from 'react';
import env from '@y2/environments';
import { SubCategoryItem } from '../../../sub-category-item/sub-category-item';
import { CategoryItem } from '../../../category-item/category-item';
import texts from './apartments-for-sale.texts.json';
const BASE_URL = `${env.routes.mainSite}/realestate/forsale/`;

export const ApartmentsForSaleCategory = () => {
  return (
    <CategoryItem title={texts.title}>
      <SubCategoryItem
        link={`${BASE_URL}?topArea=25&area=5&city=4000`}
        title={texts.haifa}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=2&area=1&city=5000`}
        title={texts.telAviv}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=2&area=9&city=8300`}
        title={texts.rishonLezion}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=75&area=77&city=3797`}
        title={texts.modiin}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=2&area=4&city=7900`}
        title={texts.petahTikva}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=41&area=21&city=0070`}
        title={texts.ashdod}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=2&area=11&city=6600`}
        title={texts.holon}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=2&area=3&city=8600`}
        title={texts.ramatGan}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=43&area=22&city=9000`}
        title={texts.beerSheva}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=100&area=7&city=3000`}
        title={texts.jerusalem}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=101&area=15&city=6500`}
        title={texts.hadera}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=19&area=18&city=6400`}
        title={texts.herzliya}
      />
      <SubCategoryItem
        link={`${BASE_URL}?topArea=41&area=21&city=7100`}
        title={texts.ashkelon}
      />
    </CategoryItem>
  );
};
