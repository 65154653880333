/* eslint-disable max-lines-per-function */
import { useState, useEffect, RefObject, useRef } from 'react';
import { Placement } from './placement-tools';

export type Status = 'open' | 'close' | 'useHover';
type TimeoutId = ReturnType<typeof setTimeout>;

export const useOpen = (
  status: Status,
  placement: Placement,
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  ref: RefObject<any>,
  closeDelay = 0,
) => {
  const [isOpen, setIsOpen] = useState<boolean | null>(status === 'open');
  const timeoutId = useRef<TimeoutId>();
  const [placementForClass, setPlacementForClass] =
    useState<Placement>(placement);

  useEffect(() => {
    setIsOpen(status === 'open');
  }, [status]);

  useEffect(() => {
    setPlacementForClass(placement);
  }, [placement]);

  useEffect(() => {
    const tooltipElm = ref.current;
    if (!tooltipElm || !isOpen) return;
    /**
     * Smart placement
     * auto move the tooltip to the top or bottom according to the remaining space in the viewport
     */
    const { left, right } = tooltipElm.getBoundingClientRect();
    const bodyWidth = document.body.offsetWidth;
    const isTop = placement.includes('top');

    if (left < 0) {
      setPlacementForClass(isTop ? 'top-left' : 'bottom-left');
    } else if (right > bodyWidth) {
      setPlacementForClass(isTop ? 'top-right' : 'bottom-right');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen]);

  return {
    isOpen,
    placementForClass,
    onHover: (action: 'enter' | 'leave') => {
      if (status !== 'useHover') return;
      if (action === 'enter') {
        clearTimeout(timeoutId.current);
        setIsOpen(true);
      } else {
        timeoutId.current = setTimeout(() => setIsOpen(false), closeDelay);
      }
    },
  };
};
