import env from '@y2/environments';
import { isWebView } from '@y2/react-native-utils';
// eslint-disable-next-line custom-rules/socket-clients-lib
import io, { Socket } from 'socket.io-client';

export { Socket };

const getGwSocketBaseParam = () => env.routes.websocket.gw.baseParam || '';
const defaultReconnectionAttempts = 3;

const webViewFlag = String(!isWebView());
const isServerSide = typeof window === 'undefined';

export const getGwSocketProvider = (
  path: string,
  reconnectionAttempts = defaultReconnectionAttempts,
) => {
  let socket: Socket | null = null;

  const createSocket = (): Socket => {
    const newSocket = io(env.routes.websocket.gw.host, {
      path: `${getGwSocketBaseParam()}${path}`,
      reconnectionAttempts,
      withCredentials: true,
      secure: true,
      transports: ['websocket'],
      extraHeaders: {
        'web-view': webViewFlag,
        'mobile-app': webViewFlag,
        // ^ for radware's antibot
      },
    });

    window.addEventListener('beforeunload', () => {
      newSocket.disconnect();
    });

    return newSocket;
  };

  return {
    getSocket: (): Socket => {
      if (env.isJestRun || isServerSide) {
        throw new Error(`Avoid using socket.io during tests or in server-side, use only in the browser
for tests use auto mock, for example:
jest.mock('@y2/socket-clients/sendbird-updates') on the top of the test file
make sure the socket client has a mock in the __mocks__ folder`);
      }

      if (!socket) {
        socket = createSocket();
        socket.connect();
      }
      return socket;
    },
    disconnect: (detachHandlers?: () => void) => {
      if (socket) {
        detachHandlers?.();
        socket.disconnect();
      }
    },
  };
};
