import React from 'react';
import styles from './footer.module.scss';
import { Button } from '@y2/mango/components/button';
import texts from './footer.texts.json';
import Link from 'next/link';
import { CategoriesList } from './categories-list/categories-list';
import { BottomLinks } from '@y2/footer';

export const Footer = () => {
  return (
    <footer className={styles.footer}>
      <div className={styles.contactUsWrapper}>
        <Link href="/contact-us">
          <Button variant="green" height="medium">
            {texts.contact}
          </Button>
        </Link>
      </div>
      <CategoriesList />
      <BottomLinks />
    </footer>
  );
};
