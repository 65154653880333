import styles from './category-item.module.scss';
import React, { useState } from 'react';
import { ArrowIcon } from '@y2/deprecated/ui/icons';
import cn from 'classnames';

type Props = {
  children: React.ReactNode;
  title: string;
};

export const CategoryItem = ({ title, children }: Props) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  return (
    <li
      className={cn(styles.categoryItem, isCollapsed && styles.isCollapsed)}
      data-nagish="footer-category-item"
    >
      <div
        className={styles.categoryNameContainer}
        onClick={() => setIsCollapsed((prevState) => !prevState)}
      >
        <h4
          className={styles.categoryItemHeading}
          data-nagish="footer-item-least-title"
        >
          {title}
        </h4>
        <ArrowIcon className={cn(styles.arrowIcon, 'mobile-only')} />
      </div>

      <ul
        className={cn(
          styles.subCategoryList,
          isCollapsed && styles.isCollapsed,
        )}
        data-nagish="footer-sub-category-list"
      >
        {children}
      </ul>
    </li>
  );
};
