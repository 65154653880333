/* eslint-disable max-lines-per-function */
import env from '@y2/environments';
import { NextApiRequest } from 'next';
import { buildAxiosConfigWithCookie, getWithSSRSupport } from '../../helpers';
import axios from '../../http-client';
import authMiddleware from '../../middlewares/auth.middleware';

export type LoginResponse = {
  data: {
    data: {
      userId: number;
      email: string;
      phone: string;
    };
  };
};

export type CustomerDataResponse = {
  data: {
    data: {
      chatDiscovered: Date;
      termsAccepted: boolean;
    };
  };
};

export type ContactInfoResponse = {
  data: {
    data: {
      phone?: string;
      email?: string;
      isAdActive: boolean;
    };
  };
};

export type UserInitialData = {
  data: {
    data: {
      createdAt: number;
      blockedList: Array<string>;
      mutedList: Array<string>;
    };
  };
};

export type CreateChannelResponse = {
  data: {
    data: {
      channel: { channel_url: string };
    };
  };
};

export type UnreadMessagesChannels = {
  data: {
    channels: {
      token: string;
      unread_messages_count: number;
    }[];
  };
};

export type UnreadMessages = {
  data: {
    data: {
      unreadCount: number;
    };
  };
};

// taken from: apps/personal-next/lib/chat/generated.ts
export type PutUpdateConversationsAdIdBody = { [key: string]: any };

const serviceName = 'chat';

const noCacheHeaders = {
  'Cache-Control': 'no-store',
  Pragma: 'no-cache',
};
/**
 * client for `chat` service
 * @see https://gw.yad2.io/chat/api-docs/#
 */

export const chatClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });
  authMiddleware(client, baseUrl);

  return {
    login: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<LoginResponse>('/v2/login', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    getCustomerData: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<CustomerDataResponse>('/customer-data', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    setTermsApproval: () =>
      client.post('/chat-customer', { termsAccepted: true }),
    contactInfo: (
      adID: string,
      isRecommerceAd = false,
      reqHeaders?: NextApiRequest['headers'],
    ) =>
      client.get<ContactInfoResponse>(`/contact-info/${adID}`, {
        params: { isRecommerceAd },
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    userInitialData: (reqHeaders?: NextApiRequest['headers']) =>
      client.get<UserInitialData>('/user-initial-data', {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    createChannel: (adId: string) =>
      client.post<CreateChannelResponse>('/conversation', { adId }),
    createRecommerceChannel: (adId: string) =>
      client.post<CreateChannelResponse>('/recommerce-ad-conversation', {
        adId,
      }),
    getAdPreview: (adId: string, isRecommerceAd = false) =>
      client.get<CreateChannelResponse>(`/adPreview/${adId}`, {
        params: { isRecommerceAd },
      }),
    updateConversations: (
      adId: string | number,
      updateConversation: PutUpdateConversationsAdIdBody,
      reqHeaders?: NextApiRequest['headers'],
    ) =>
      client.put(`/update-conversations/${adId}`, updateConversation, {
        ...buildAxiosConfigWithCookie(reqHeaders),
      }),
    getUnreadMessageCount: (reqHeaders?: NextApiRequest['headers']) =>
      client.get('/unread-messages-count', {
        ...buildAxiosConfigWithCookie({
          ...reqHeaders,
          ...noCacheHeaders,
        }),
      }),
    sendQuickMessage: (
      adId: string,
      messageText: string,
      reqHeaders?: NextApiRequest['headers'],
    ) => {
      return client.post<CreateChannelResponse>('/conversation/quick-message', {
        ...buildAxiosConfigWithCookie(reqHeaders),
        ...{ adId, messageText },
      });
    },
    getUnreadMessagesPerChannel: (
      tokens: string[],
      reqHeaders?: NextApiRequest['headers'],
    ) =>
      client.get<UnreadMessagesChannels>(
        `/channels/unread-messages-count?adIds=${encodeURIComponent(JSON.stringify(tokens))}`,
        {
          ...buildAxiosConfigWithCookie(reqHeaders),
        },
      ),
  };
};
