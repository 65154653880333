import { UseQueryOptions } from '@tanstack/react-query';
import { myAlerts } from './clients';

const getQueryKey = () => ['my-alerts', 'count'];

export const getMyAlertsCount = async (): Promise<number | undefined> => {
  const {
    data: { data },
  } = await myAlerts.count();
  return data?.alertsCount;
};

export const getMyAlertsCountOptions = (
  isLoggedIn: boolean,
): UseQueryOptions<number | undefined> => {
  return {
    queryKey: getQueryKey(),
    queryFn: () => getMyAlertsCount(),
    enabled: isLoggedIn,
  };
};
