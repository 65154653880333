import { ResponsiveHeaderGrid, Logo } from '@y2/header';
import { useUserDetails } from '@y2/auth-common/user-details-query';
import { FavoritesDropMenu } from '@y2/favorites/react-query/components/favorites-dropdown';
import { pushHeaderUserItemEvent } from '../../features/analytics/feed-events';
import texts from './header.texts.json';
import styles from './header.module.scss';
import { Button } from '@y2/mango/components/button';
import Link from 'next/link';
import { useNativeApp } from '@y2/hooks/use-native-app';
import { HeaderUserDrop } from './header-user-drop/header-user-drop';
import { HeaderMainMenu } from './header-main-menu/header-main-menu';
import env from '@y2/environments';

export const Header = () => {
  const { data: user } = useUserDetails();
  const isLoggedIn = !!user;
  const isNativeApp = useNativeApp();

  if (isNativeApp) return null;

  return (
    <ResponsiveHeaderGrid
      className={styles.header}
      theme="yad1"
      userDropSlot={<HeaderUserDrop />}
      logoSlot={
        <Logo imageToUse="yad1" linkAddress={`${env.routes.mainSite}/yad1`} />
      }
      mobileLogoSlot={
        <Logo
          isSmall
          imageToUse="yad1"
          linkAddress={`${env.routes.mainSite}/yad1`}
        />
      }
      userDropDownExtension={<FavoritesDropMenu isLoggedIn={isLoggedIn} />}
      mainMenuSlot={<HeaderMainMenu />}
      leftButtonSlot={
        <Link href="/contact-us" className={styles.contactUsLink}>
          <Button
            variant="blueOutlined"
            onClick={() => pushHeaderUserItemEvent(texts.contactUs)}
            mobileOnlySize="medium"
            desktopOnlySize="small"
          >
            {texts.contactUs}
          </Button>
        </Link>
      }
    />
  );
};
