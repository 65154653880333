import React from 'react';
import { Header } from '../header';
import { Footer } from '../../features/footer/footer';

type Props = {
  children?: React.ReactNode;
};

export const GeneralLayout = ({ children }: Props) => {
  return (
    <>
      <Header />
      {children}
      <Footer />
    </>
  );
};
