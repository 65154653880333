import { useEffect } from 'react';
import uniqueId from 'lodash/uniqueId';

type Args = {
  id: string | undefined;
  setId: (value: string) => void;
  prefix: string;
};
/**
 * provides id incase it was not passed
 * in hook to avoid miss-match rendering between client & server
 */
export const useUniqueIdEnforcer = ({ id, setId, prefix }: Args) => {
  useEffect(() => {
    if (!id) {
      setId(uniqueId(`${prefix}-`));
    }
  }, []);
};
