import cn from 'classnames';
import styles from './price-column.module.scss';
import text from './price-column.text.json';

type Props = {
  price: string;
};

export const PriceColumn = ({ price }: Props) => (
  <span className={cn(styles.col, styles.priceCol)}>
    {price || text.NO_PRICE_MENTIONED}
  </span>
);
