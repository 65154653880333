import axios from '../http-client';
import env from '@y2/environments';
import { getWithSSRSupport } from '../helpers';
import { OkResponse } from '../helpers/response-wrappers';

export type SearchItem = {
  id: string;
  userId: string;
  url: string;
  urlCompare: string;
  categoryId: number;
  subCategoryId: number;
  updatedAt: string;
  createdAt: string;
  count: number;
  userAgent?: string;
  title: string;
  fields: {
    [name: string]: {
      label: string;
      text: string;
      value: string;
    };
  };
  isAlertable: boolean;
  isAlerted?: boolean;
  alertId?: string;
};

export type LatestSearchesResponse = OkResponse<SearchItem[]>;

export type ReqOptions = {
  limit?: number;
  offset?: number;
};

const serviceName = 'latest-searches';

/**
 * client for `latest-searches` service
 * @see https://gw.yad2.io/latest-searches/api-docs/#
 */
export const latestSearchesClient = (baseUrl = env.routes.api.gw) => {
  const client = axios.create({
    baseURL: getWithSSRSupport(baseUrl, serviceName),
    withCredentials: true,
  });

  return {
    count: () => client.get<OkResponse<number>>('/count'),
    getAll: (options?: ReqOptions) =>
      client.get<LatestSearchesResponse>('/', {
        params: {
          limit: options?.limit,
          offset: options?.offset,
        },
      }),
    getByCategory: (category: number, options?: ReqOptions) =>
      client.get<LatestSearchesResponse>(`/category/${category}`, {
        params: {
          limit: options?.limit,
          offset: options?.offset,
        },
      }),
    add: (searchUrl: string) =>
      client.post('/', { url: searchUrl }, { timeout: 2000 }),
    remove: (ids: string[]) => client.delete(`/${ids.join(',')}`),
  };
};
