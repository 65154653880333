import styles from './sub-category-item.module.scss';
import React from 'react';

type Props = {
  link: string;
  title: string;
};

export const SubCategoryItem = ({ link, title }: Props) => {
  return (
    <li
      className={styles.subCategoryItem}
      data-nagish="footer-sub-category-item"
    >
      <a href={link} target="_self" data-nagish="footer-sub-category-item-link">
        {title}
      </a>
    </li>
  );
};
