import { RecommerceFavoriteItem } from '@y2/api-clients/favorites.types';
import type { FavoriteItem } from '../../service';
import { RecommerceProduct } from '@y2/api-clients/recommerce-feed.types';
import { GENERIC_DEFAULT_IMAGE } from '../../service/default-images';
import env from '@y2/environments';
import { LikedItemStorage } from '../../service/local-storage';
import texts from '../favorites-drop-item/item-info-column/item-info-column.text.json';

export const addHrefForAnalytics = (appName: string, index: number) => {
  const params = {
    'opened-from': appName,
    'component-type': 'favorites_header',
    spot: 'standard',
    location: String(index + 1),
  };

  return new URLSearchParams(params).toString();
};

const konesUrl = (query: string, id: string) => {
  return `/kones/item/${id}?${query}`;
};

const recommendedUrl = (query: string, urlIdentifier?: string) => {
  const baseUrl = env.routes.marketYad2;
  const baseParams = '/products';
  return `${baseUrl}${baseParams}/${urlIdentifier}?${query}`;
};

export const getItemPageUrl = (
  item: FavoriteItem,
  appName: string,
  index: number,
): string => {
  const query = addHrefForAnalytics(appName, index);
  const baseParams = '/item';

  if (item.isKones) {
    return konesUrl(query, item.id);
  }

  if (item.isRecommerce) {
    return recommendedUrl(query, item.urlIdentifier);
  }

  return `${baseParams}/${item.id}?${query}`;
};

export const getImageUrl = (mainImage: string, additionalImages: string[]) => {
  return (
    mainImage || (additionalImages.length > 0 ? additionalImages[0] : undefined)
  );
};

export const getPrice = (price: number) =>
  price ? `${price} ₪` : texts.NO_PRICE_MENTIONED;

export const mapRecommerceFavoriteItemToFavoriteItem = (
  items: RecommerceFavoriteItem[],
): FavoriteItem[] => {
  return items.map((item) => {
    const itemData = item as RecommerceFavoriteItem;
    const price = getPrice(itemData.price);
    return {
      id: itemData.shopifyProductId.toString(),
      images: {
        main: itemData.image || GENERIC_DEFAULT_IMAGE,
        additional: [],
      },
      price,
      title: itemData.title,
      subTitle: itemData.subTitle,
      city: itemData.city,
      comment: itemData.comment || '',
      catId: 20,
      subCatId: 0,
      isKones: false,
      savedAt: new Date(itemData.savedAt),
      createdAt: new Date(itemData.updatedAt),
      isRecommerce: true,
      urlIdentifier: itemData.urlIdentifier,
    };
  });
};

export const mapRecommerceProductToFavoriteItem = (
  items: RecommerceProduct[],
  mappedLikedItems?: Record<string, LikedItemStorage>,
): FavoriteItem[] =>
  items.map((item) => ({
    id: item.id.toString(),
    images: {
      main: item.images[0] || GENERIC_DEFAULT_IMAGE,
      additional: item.images.slice(1),
    },
    price: getPrice(item.price),
    title: item.title,
    subTitle: item.description,
    city: item.address.city.textHeb,
    comment: '',
    catId: 20,
    subCatId: 0,
    isKones: false,
    savedAt: new Date(item.createAt),
    createdAt: mappedLikedItems
      ? new Date(mappedLikedItems[item.id].updatedAt)
      : undefined,
    isRecommerce: true,
    urlIdentifier: item.urlIdentifier,
  }));

export const isCreateBeforeSecond = (first?: Date, second?: Date) =>
  (first?.getTime() ?? 0) - (second?.getTime() ?? 0);
