import styles from './profile-element.module.scss';
import text from '../../user-drop-container.text.json';
import { UserIcon } from '@y2/mango/components/icons';

export const ProfileElement = ({
  userProfileImg,
  userInitials,
}: {
  userProfileImg: string;
  userInitials: string;
}) => {
  if (userProfileImg)
    return (
      <img
        className={styles.img}
        src={userProfileImg}
        alt={text.PROFILE_IMG_ATL}
      />
    );

  if (userInitials)
    return <span className={styles.userInitials}>{userInitials}</span>;

  return <UserIcon className={styles.icon} />;
};
