import type { NextPage } from 'next';
import { log } from '@y2/log';
import { ServerErrorSection, BadRequestErrorSection } from '@y2/error-handlers';
import { useEffect } from 'react';
import Head from 'next/head';
import { BadRequestError } from '../services/errors/page-errors';
import { STATUS_CODES } from '@y2/api-clients';
import { GeneralLayout } from '../components/general-layout/general-layout';

type Props = {
  statusCode?: number;
};

const DEFAULT_ERROR_CODE = 500;

type BoundaryFallbackArgs = {
  error: Error;
};

export const BoundaryFallback = ({ error }: BoundaryFallbackArgs) => {
  useEffect(() => {
    if (error) {
      log.error(error, 'failed to render page');
    }
  }, [error]);

  return <ServerErrorSection />;
};

const ErrorPage: NextPage<Props> = (props) => {
  const isBrowser = typeof window !== 'undefined';
  const hydratedStatusCode = isBrowser
    ? document
        .querySelector('meta[name="hydrated-statusCode"]')
        ?.getAttribute('content')
    : null;
  const statusCode =
    hydratedStatusCode || props.statusCode || DEFAULT_ERROR_CODE;
  const isBadRequest = Number(statusCode) === STATUS_CODES.BAD_REQUEST;

  return (
    <>
      <Head>
        <meta name="hydrated-statusCode" content={String(statusCode)} />
      </Head>
      <GeneralLayout>
        {isBadRequest ? <BadRequestErrorSection /> : <ServerErrorSection />}
      </GeneralLayout>
    </>
  );
};

ErrorPage.getInitialProps = ({ res, err }) => {
  const errCode = err?.statusCode;
  const resCode = res?.statusCode;
  const statusCode = errCode || resCode || DEFAULT_ERROR_CODE;
  if (err instanceof BadRequestError) {
    // eslint-disable-next-line no-param-reassign
    (res as any).statusCode = STATUS_CODES.BAD_REQUEST;

    return {
      statusCode: STATUS_CODES.BAD_REQUEST,
    };
  } else if (err) {
    log.error(err, 'failed to render page');
  }

  return {
    statusCode,
  };
};

export default ErrorPage;
