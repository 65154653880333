import React, { ElementType, HTMLAttributes } from 'react';
import styles from './option.module.scss';
import cn from 'classnames';
import noop from 'lodash/noop';

type GeneralButton = HTMLAttributes<HTMLElement> & {
  href?: string;
  target?: string;
  'data-testid'?: string;
};

type Props = GeneralButton & {
  icon: React.ReactNode;
  text?: string;
  as?: ElementType;
  count?: number;
  countOnIcon?: boolean;
  url?: string;
  onClick?: () => void;
  sendAnalytics?: () => void;
  customStyle?: 'logout' | 'hoisted';
  className?: string;
};

const CountSpan = (args: { count: number }) =>
  args.count ? <span className={styles.count}>{args.count}</span> : null;

const TextSpan = (args: { text: string }) => (
  <span className={styles.text}>{args.text}</span>
);

const Option: React.FC<Props> = ({
  icon,
  onClick = noop,
  sendAnalytics = noop,
  text = '',
  as: HtmlElement = 'a',
  count = 0,
  url = '',
  customStyle = '',
  countOnIcon = false,
  className,
  children,
  ...attributes
}) => {
  const handleOnClick = () => {
    onClick?.();
    sendAnalytics?.();
  };

  return (
    <HtmlElement
      {...attributes}
      className={cn(
        styles.option,
        customStyle && styles[customStyle],
        className,
      )}
      data-header-action-on-click="close-mobile-menu"
      {...(HtmlElement === 'a' ? { href: url } : {})}
      onClick={handleOnClick}
      data-testid={attributes['data-testid'] || 'header-option'}
      data-nagish="header-profile-menu-option"
    >
      {countOnIcon ? (
        <>
          <div className={styles.iconBox} data-testid="count-on-icon">
            {icon}
            <CountSpan count={count} />
          </div>
          {text && <TextSpan text={text} />}
          {children}
        </>
      ) : (
        <>
          {icon}
          {text && <TextSpan text={text} />}
          {children}
          <CountSpan count={count} />
        </>
      )}
    </HtmlElement>
  );
};

export default Option;
