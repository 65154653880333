import Modal, { ModalProps } from '..';
import { AttentionSpotIllustration } from '../../illustrations/spot';
import text from './text.json';
import styles from './error-notice.module.scss';

type Props = Omit<
  ModalProps,
  'label' | 'description' | 'useBrowserBackButton' | 'data-nagish'
> & {
  customErrorMessage?: string;
};

export const ErrorNotice = ({
  customErrorMessage,
  children,
  ...props
}: Props) => (
  <Modal
    {...props}
    label={text.ERROR_MODAL}
    description={text.ERROR_DESCRIPTION}
    data-nagish="error-notice-dialog"
  >
    <div className={styles.errorNotice}>
      <AttentionSpotIllustration />
      <p>{customErrorMessage || text.DEFAULT_ERROR_MESSAGE}</p>
      {children}
    </div>
  </Modal>
);
