import { useEffect } from 'react';
import { getGwSocketProvider, Socket } from '../../socket-client';
import {
  CHAT_SOCKET_EVENTS,
  EVENT_HANDLER_SWITCH,
  PAGE_SHOW,
  SOCKET_PATH,
} from './consts';
import { EventHandlerSwitch, UseChatUpdatesProps } from './types';
import { log } from '@y2/log';

const { ON_INCOMING, ON_CONNECT_ERROR, ON_ERROR } = CHAT_SOCKET_EVENTS;
const { ATTACH_HANDLERS, DETACH_HANDLERS } = EVENT_HANDLER_SWITCH;

let socket: Socket;

const createChatSocket = ({
  onMessage,
  onBfCache,
}: Pick<UseChatUpdatesProps, 'onMessage' | 'onBfCache'>) => {
  socket = socket || getGwSocketProvider(SOCKET_PATH).getSocket();

  const eventHandlerSetup = (action: EventHandlerSwitch) => {
    socket[action](ON_INCOMING, onMessage);
    socket[action](ON_CONNECT_ERROR, onError);
    socket[action](ON_ERROR, onError);
  };

  const onError = (error: Error) => {
    const errorMessage = ['Chat socket error', error.message].join(' ');

    log.info(errorMessage);
  };

  const initChatSocket = () => {
    eventHandlerSetup(ATTACH_HANDLERS);
    window.addEventListener(PAGE_SHOW, handleRestartSocketOnBfCache);
    onBfCache();
  };

  const handleRestartSocketOnBfCache = (event: PageTransitionEvent) => {
    const isPageFromBrowserCache = event.persisted;
    if (!isPageFromBrowserCache) return;

    initChatSocket();
    onBfCache();
  };

  const removeListeners = () => eventHandlerSetup(DETACH_HANDLERS);

  return { initChatSocket, removeListeners };
};

export const useChatUpdates = ({
  isUserLoggedIn,
  onMessage,
  onBfCache,
}: UseChatUpdatesProps) => {
  useEffect(() => {
    const { initChatSocket, removeListeners } = createChatSocket({
      onMessage,
      onBfCache,
    });

    if (isUserLoggedIn) {
      initChatSocket();
    } else {
      removeListeners();
    }

    return removeListeners;
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isUserLoggedIn]);
};
