import { QueryClient } from '@tanstack/react-query';
import { log } from '@y2/log';
import {
  favoriteClient,
  FavoriteItem,
  legacyFeedClient,
  LikedItemData,
} from '../clients';
import {
  FetchFavorites,
  RemoveMutationOptions,
  AddMutationOptions,
} from './types';
import { fromApiData } from '../../service';
import { isAxiosError, STATUS_CODES } from '@y2/api-clients';
import {
  isCreateBeforeSecond,
  mapRecommerceFavoriteItemToFavoriteItem,
  mapRecommerceProductToFavoriteItem,
} from '../../components/favorites-item/helpers';
import { recommerceFeedClient } from '@y2/api-clients/recommerce-feed';
import * as localStorage from '../../service/local-storage';

const recommerceClient = recommerceFeedClient();

export const getQueryKey = () => ['user', 'favorites', 'logged'];

export const createLocalFavoritesInDb = async () => {
  const localRecommerceTokens = localStorage
    .getAllRecommerce()
    ?.map((item) => item.adId);

  const localTokens = localStorage.getAll()?.map((item) => item.adId);
  const tokens = [...localRecommerceTokens, ...localTokens];

  if (!tokens?.length) return;

  try {
    await favoriteClient.addMulti(tokens);
    localStorage.removeAll();
    localStorage.removeAllRecommerce();
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response?.status === STATUS_CODES.GATEWAY_TIMEOUT
    ) {
      log.info('failed to create local favorites in db');
    } else {
      log.error(err, 'failed to create local favorites in db');
    }
    throw err;
  }
};

export const fetchRecommerceFavorites = async () => {
  await createLocalFavoritesInDb();

  const res = await favoriteClient.getAll();
  return res?.data?.data?.recommerceFavorites;
};

export const fetchFavorites: FetchFavorites = async () => {
  try {
    await createLocalFavoritesInDb();

    const res = await favoriteClient.getAll();

    const recommerceFavoritesData = res?.data?.data?.recommerceFavorites;

    const recommerceFavorites = mapRecommerceFavoriteItemToFavoriteItem(
      recommerceFavoritesData,
    );

    const favorites = res?.data?.data?.favorites;

    const favoritesMap = favorites.reduce(
      (allFavorites, favorite) => ({
        ...allFavorites,
        [favorite.orderId]: favorite,
      }),
      {} as Record<string, FavoriteItem>,
    );

    const tokens = Object.keys(favoritesMap);
    if (!tokens?.length) return recommerceFavorites || [];

    const feedRes = await legacyFeedClient.getMany(tokens);

    return [
      ...recommerceFavorites,
      ...feedRes.data.map((item) =>
        fromApiData(item, favoritesMap[item.id]?.updatedAt),
      ),
    ].sort((first, second) =>
      isCreateBeforeSecond(first.createdAt, second.createdAt),
    );
  } catch (err) {
    if (
      isAxiosError(err) &&
      err.response?.status === STATUS_CODES.GATEWAY_TIMEOUT
    ) {
      log.info('failed to fetch logged all favorites');
    } else {
      log.error(err, 'failed to fetch logged all favorites');
    }
    throw err;
  }
};

export const getRemoveMutationOptions = (
  queryClient: QueryClient,
): RemoveMutationOptions => ({
  mutationFn: ({ token }) => favoriteClient.remove(token),
  onSuccess: (data, { token }) => {
    const queryKey = getQueryKey();
    const favorites = queryClient.getQueryData<LikedItemData[]>(queryKey);

    if (favorites) {
      const updatedFavorites = favorites.filter((item) => item.id !== token);
      queryClient.setQueryData(queryKey, updatedFavorites);
    }
  },
});

export const getAddMutationOptions = (
  queryClient: QueryClient,
): AddMutationOptions => ({
  mutationFn: async ({ token, isRecommerce = false }) => {
    favoriteClient.add(token);
    if (isRecommerce) {
      const itemRes = await recommerceClient.getProductsByShopifyIds([
        Number(token),
      ]);
      return mapRecommerceProductToFavoriteItem(itemRes)[0];
    } else {
      const itemRes = await legacyFeedClient.get(token);

      return fromApiData(itemRes.data);
    }
  },

  onSuccess: (itemData, { token }) => {
    const queryKey = getQueryKey();
    const favorites = queryClient.getQueryData<LikedItemData[]>(queryKey) || [];

    queryClient.setQueriesData(queryKey, [...favorites, itemData]);
  },
  onError: (err) => {
    log.error(err, 'failed to post like click on favorites while logged-in');
    throw err;
  },
});
