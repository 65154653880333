import { analytics } from '@y2/google-tag-manager';

export const pushEnterChatSideMenuAnalitycsEvent = () =>
  analytics.pushEvent({
    event: 'dl_event',
    EventName: 'chat_opened',
    EventDetail: 'side_menu',
    EventText: 'null',
    EventGroup: 'chat_interactions',
  });
