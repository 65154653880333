import React from 'react';
import styles from './option-icon.module.scss';
import Option from '../option/option';
import cn from 'classnames';
import { DoorIcon } from '@y2/deprecated/ui/icons';
import texts from './default-options.text.json';

type Props = {
  onClick: () => void;
};

const LogoutOption: React.VFC<Props> = ({ onClick }) => (
  <Option
    as={'button'}
    icon={
      <DoorIcon className={cn(styles.optionIcon, styles.removeFromMobile)} />
    }
    text={texts.DISCONNECT}
    onClick={onClick}
    customStyle={'logout'}
    date-testid="logout-option"
  />
);

export default LogoutOption;
