import { useState, MouseEvent, CSSProperties, ComponentProps } from 'react';
import cn from 'classnames';
import { useLikeStatus } from './use-like-status';
import texts from './like-button.text.json';
import styles from './like-toggle.module.scss';
import noop from 'lodash/noop';
import { Tooltip } from '@y2/mango/components/tooltip';
import { Colors, LikeIcon } from './like-icon';
import { ErrorNotice } from '@y2/mango/components/modal/error-notice';
import { useEffectWithoutFirstRender } from '@y2/hooks/use-effect-without-first-render';
import { analytics } from '@y2/google-tag-manager/v2';

type ButtonAttributes = Omit<
  React.DetailedHTMLProps<
    React.ButtonHTMLAttributes<HTMLButtonElement>,
    HTMLButtonElement
  >,
  'onClick'
>;

const THREE_SECONDS = 3000;

const ANIMATION_DURATION = 800;

type ButtonEvent = MouseEvent<HTMLButtonElement, globalThis.MouseEvent>;

type Placement = ComponentProps<typeof Tooltip>['placement'];

export type Props = ButtonAttributes & {
  token: string;
  color?: Colors;
  children?: React.ReactNode;
  onClick?: (isLiked?: boolean, event?: ButtonEvent) => void;
  onDislike?: () => void;
  onLike?: () => void;
  disableTooltip?: boolean;
  hasFrame?: boolean;
  buttonClassName?: string;
  iconClassName?: string;
  placementTooltip?: Placement;
  isRecommerce?: boolean;
};

export const LikeButton = ({
  token,
  onLike,
  onDislike,
  className,
  children,
  buttonClassName,
  iconClassName,
  color = 'black',
  disableTooltip = false,
  hasFrame = false,
  onClick = noop,
  placementTooltip = 'left',
  isRecommerce = false,
  ...buttonAttributes
}: Props) => {
  const [showToolTip, setShowToolTip] = useState(false);
  const [showErrorNotice, setShowErrorNotice] = useState(false);
  const { isLiked, update } = useLikeStatus(token, isRecommerce, () =>
    setShowErrorNotice(true),
  );
  const [enableHover, setEnableHover] = useState(!isLiked);

  useEffectWithoutFirstRender(() => {
    if (!isLiked) {
      setTimeout(() => setEnableHover(true), ANIMATION_DURATION);
    } else {
      setEnableHover(false);
    }
  }, [isLiked]);

  const handleAnalytics = () => {
    analytics.pushEvent({
      EventName: 'like',
      EventDetail: 'null',
      EventText: 'null',
      EventGroup: 'item_interactions',
    });
  };

  const handleOnCLick = (event: ButtonEvent, isLiked: boolean) => {
    event.stopPropagation();
    update(isLiked);
    onClick(isLiked, event);
    if (isLiked) {
      handleAnalytics();
      setShowToolTip(true);
      setTimeout(() => setShowToolTip(false), THREE_SECONDS);
    }
  };

  return (
    <>
      <Tooltip
        content={<span>{texts.tooltipText}</span>}
        placement={placementTooltip}
        status={!disableTooltip && showToolTip ? 'open' : 'close'}
        className={className}
      >
        <button
          {...buttonAttributes}
          className={cn(
            styles.likeButton,
            hasFrame && styles.hasFrame,
            buttonClassName,
          )}
          aria-label={texts.saveAd}
          data-testid="like-button"
          onClick={(event: ButtonEvent) => handleOnCLick(event, !isLiked)}
          data-nagish="like-icon-button"
        >
          <LikeIcon
            color={color}
            isLiked={isLiked}
            className={cn(
              enableHover && styles.likeHover,
              iconClassName || styles.defaultIconClassName,
            )}
            speed={1.7}
          />
          {children}
        </button>
      </Tooltip>
      <ErrorNotice
        customErrorMessage={texts.errorNotice}
        onClose={() => setShowErrorNotice(false)}
        open={showErrorNotice}
      />
    </>
  );
};
