import React from 'react';
import styles from './user-drop-item.module.scss';
import noop from 'lodash/noop';
import cn from 'classnames';
import {
  defaultOptionsComponents,
  DefaultOptionId,
} from '../options-collection/default-options';

type Props = {
  id: DefaultOptionId;
  count?: number;
  isMobileOnly?: boolean;
  className?: string;
  onClick?: () => void;
  isUserLoggedIn?: boolean;
};

export const UserDropItem: React.VFC<Props> = ({
  id,
  count = 0,
  isMobileOnly = false,
  className = '',
  onClick = noop,
  isUserLoggedIn,
}) => {
  const optionRenderer = defaultOptionsComponents[id];

  return (
    <li
      className={cn(
        styles['option-item'],
        isMobileOnly && styles['is-mobile-only'],
        className && styles[className],
      )}
      data-nagish="header-drop-item"
    >
      {optionRenderer(onClick, count, isUserLoggedIn)}
    </li>
  );
};

export default UserDropItem;
